var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "room-batch" },
    [
      _c("app-header", { attrs: { title: "房间批量添加", isShowBack: true } }),
      _c("div", { staticClass: "row-title" }, [_vm._v("基础信息")]),
      _c("app-form", {
        attrs: { opt: _vm.baseOpt, entry: "roomDatchBaseForm" }
      }),
      _c("div", [
        _c("div", { staticClass: "row-title" }, [_vm._v("房间信息")]),
        _c(
          "div",
          { staticClass: "list-box" },
          [
            _c("div", { staticClass: "list-item" }, [
              _c(
                "div",
                { staticClass: "list-cols" },
                [
                  _c("label", [_vm._v("房间数")]),
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入房间数",
                      maxlength: "3",
                      onkeypress:
                        "return (/[\\d]/.test(String.fromCharCode(event.keyCode)))"
                    },
                    on: { input: _vm.longFun },
                    model: {
                      value: _vm.roomNumLong,
                      callback: function($$v) {
                        _vm.roomNumLong = $$v
                      },
                      expression: "roomNumLong"
                    }
                  }),
                  _c("span", [_vm._v("输入所需的房间数自动生成下面列数")])
                ],
                1
              )
            ]),
            _vm._l(_vm.roomList, function(item, index) {
              return _c("div", { key: index, staticClass: "list-item" }, [
                _c("div", { staticClass: "list-idx" }, [
                  _vm._v(_vm._s(index + 1) + ".")
                ]),
                _c(
                  "div",
                  { staticClass: "list-col" },
                  [
                    _c("label", [_vm._v("房号")]),
                    _c("el-input", {
                      attrs: { placeholder: "请输入房号", maxlength: "24" },
                      model: {
                        value: item.roomNo,
                        callback: function($$v) {
                          _vm.$set(item, "roomNo", $$v)
                        },
                        expression: "item.roomNo"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "list-buts" },
                  [
                    _c("el-button", {
                      staticClass: "list-but",
                      attrs: { type: "primary", icon: "el-icon-plus" },
                      on: {
                        click: function($event) {
                          _vm.addItem(index)
                        }
                      }
                    }),
                    _c("el-button", {
                      staticClass: "list-but",
                      attrs: { type: "danger", icon: "el-icon-minus" },
                      on: {
                        click: function($event) {
                          _vm.delItem(index)
                        }
                      }
                    })
                  ],
                  1
                )
              ])
            })
          ],
          2
        )
      ]),
      _c(
        "footer",
        { staticClass: "action-but" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  _vm.onSubmit()
                }
              }
            },
            [_vm._v("提交")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }