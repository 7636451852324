<template>
  <div class="room-batch">
    <app-header title="房间批量添加" :isShowBack="true"></app-header>

    <div class="row-title">基础信息</div>
    <app-form :opt="baseOpt" entry="roomDatchBaseForm"></app-form>

    <div>
      <div class="row-title">房间信息</div>
      <div class="list-box">
        <div class="list-item">
          <div class="list-cols">
            <label>房间数</label>
            <el-input
              v-model="roomNumLong"
              v-on:input="longFun"
              placeholder="请输入房间数"
              maxlength="3"
              onkeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
            ></el-input>
            <span>输入所需的房间数自动生成下面列数</span>
          </div>
        </div>
        <div class="list-item" v-for="(item, index) in roomList" :key="index">
          <div class="list-idx">{{ index + 1 }}.</div>
          <div class="list-col">
            <label>房号</label>
            <el-input
              v-model="item.roomNo"
              placeholder="请输入房号"
              maxlength="24"
            ></el-input>
          </div>
          <div class="list-buts">
            <el-button
              class="list-but"
              type="primary"
              icon="el-icon-plus"
              @click="addItem(index)"
            ></el-button>
            <el-button
              class="list-but"
              type="danger"
              icon="el-icon-minus"
              @click="delItem(index)"
            ></el-button>
          </div>
        </div>
      </div>
    </div>
    <footer class="action-but">
      <el-button type="primary" @click="onSubmit()">提交</el-button>
    </footer>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      baseOpt: {
        form: [
          {
            label: "房源",
            key: "apartmentId",
            type: "select",
            isCol33: true,
            opt: {
              list: [],
              change(data) {
                // 赋值楼栋数
                let list = _this.baseOpt.form[0].opt.list;
                _this.baseOpt.form[3].opt.list = [];
                list.forEach((item) => {
                  if (item.value == data) {
                    if (item.buildingList && item.buildingList.length > 0) {
                      item.buildingList.forEach((it) => {
                        _this.baseOpt.form[3].opt.list.push({
                          label: it.buildName,
                          value: Number(it.buildCode),
                        });
                      });
                    } else {
                      for (var i = 0; i < item.buildings; i++) {
                        _this.baseOpt.form[3].opt.list.push({
                          label: i + 1 + "栋",
                          value: i + 1,
                        });
                      }
                    }
                    _this.buildingsList = _this.baseOpt.form[3].opt.list;
                  }
                });
                _this.getTypeIdList(data);
              },
            },
            rules: [{ required: true }],
          },
          {
            label: "房型",
            key: "roomType",
            type: "select",
            isCol33: true,
            opt: {
              list: [],
              parent: "apartmentId",
            },
            rules: [{ required: true }],
          },
          {
            label: "楼层",
            key: "floor",
            type: "number",
            isCol33: true,
            isWidth100: true,
            rules: [{ required: true }],
          },
          {
            label: "楼栋名称",
            key: "buildingNo",
            type: "select",
            isCol33: true,
            opt: {
              list: [],
              parent: "apartmentId",
            },
            rules: [{ required: true }],
          },
          {
            label: "租赁模式",
            key: "rentalStyle",
            type: "select",
            opt: {
              list: [
                {
                  label: "长租",
                  value: "0",
                },
              ],
            },
            isCol33: true,
            rules: [{ required: true }],
          },
        ],
      },
      roomList: [],
      roomTemplate: {
        roomNo: "",
        rentalOriginalFee: "", //长租原价
        rentalFee: "", //长租折后价
        deposit: "", //长租押金
        propertyFee: "", //物业费,
        shortOriginalFee: "", //短租原价
        shortRentFee: "", //短租折后价
        shortDeposit: "", //短租押金
      },
      roomTemplateCache: null,
      isShowLong: true,
      isShowShort: false,
      isShowClock: false,
      isLongDiscount: false,
      isShortDiscount: false,
      roomNumLong: "",
      apartmentList: [],
      buildingsList: [],
    };
  },
  created: function () {
    console.log("room-batch created!!");
  },
  activated() {
    this.getApartmentList();
    this.initBatchList();
  },
  methods: {
    keyUpChange(index, label) {
      let _this = this;
      _this.roomList[index][label] = this.clearNoNum(
        _this.roomList[index][label].toString()
      );
    },
    //控制只能输入数字和小数点后2位
    clearNoNum(value) {
      value = value.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
      value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数

      if (value.indexOf(".") < 0 && value != "") {
        //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        value = parseFloat(value);
      }
      return value;
    },
    //初始化批量列表
    initBatchList() {
      this.isLongDiscount = false;
      this.isShortDiscount = false;
      this.roomNumLong = "5";
      let _list = [];
      for (let i = 0; i < 5; i++) {
        _list.push(JSON.parse(JSON.stringify(this.roomTemplate)));
      }
      this.roomList = _list;
    },
    //获取房源列表
    getApartmentList() {
      var dto = {
        pageNumber: "1",
        pageSize: "99999",
        landlordId:
          this.cache.getLS("userInfo")["roleType"] == "1"
            ? null
            : this.cache.getLS("userInfo")["userId"],
      };
      this.post("landlord-service/apartment/queryPagesApartment", dto).then(
        (res) => {
          res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
          res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
          this.baseOpt.form[0].opt.list = res;
          this.apartmentList = res;
        }
      );
    },
    // 获取房型
    getTypeIdList(apartmentId) {
      this.post("landlord-service/base/getRoomTypeAll", {}).then((res) => {
        let baseType = {};
        res.forEach((item) => {
          baseType[item.id] = item.name;
        });
        this.post("landlord-service/roomType/queryPagesRoomType", {
          pageSize: "99999",
          pageNumber: "1",
          landlordId:
            this.cache.getLS("userInfo")["roleType"] == "1"
              ? null
              : this.cache.getLS("userInfo")["userId"],
          apartmentId: apartmentId,
        }).then((resData) => {
          resData.forEach((item) => {
            item.label = baseType[item.typeId];
            item.value = item.typeId;
          });
          this.baseOpt.form[1].opt.list = resData;
        });
      });
    },
    //增加行
    addItem(idx) {
      //   console.log(idx);
      if (this.roomList.length == 20) {
        this.$message({
          showClose: true,
          message: "最多添加至20项",
          type: "warning",
        });
      } else {
        this.roomList.splice(
          idx + 1,
          0,
          JSON.parse(JSON.stringify(this.roomTemplate))
        );
        this.roomNumLong = this.roomList.length;
      }
    },
    //删除行
    delItem(idx) {
      //   console.log(idx);

      if (this.roomList.length == 1) {
        this.$message({
          showClose: true,
          message: "至少保留1项",
          type: "warning",
        });
      } else {
        this.roomNumLong = this.roomList.length - 1;
        this.roomList.splice(idx, 1);
      }
    },
    /***
     * 提交
     */
    onSubmit() {
      let base = new Promise((resolve, reject) => {
        this.$tel.call("roomDatchBaseForm", (refForm, formData) => {
          refForm.validate((valid) => {
            if (valid) {
              resolve(formData);
            } else {
              reject();
            }
          });
        });
      });
      base.then((baseData) => {
          this.send(baseData,this.roomList);
        }).catch((e) => {
          console.error(e);
          this.$message({
            showClose: true,
            message: "请输入需要填写的信息",
            type: "warning",
          });
        });
    },
    send(baseData,roomList) {
      let _this = this;
      console.log("roomList:", roomList);
      let dto = [];
      for (let i = 0; i < roomList.length; i++) {
        let room = JSON.parse(JSON.stringify(roomList[i]));
        if (i == 0) {
          let reg = /^[a-zA-Z0-9\u4e00-\u9fa5]{1,24}$/;
          if (!room.roomNo) {
            return _this.$message({
              showClose: true,
              message: "序号为" + (i + 1) + "的房间必须填写房号",
              type: "warning",
            });
          }
          if (!reg.test(room.roomNo)) {
            return _this.$message({
              showClose: true,
              message:
                "序号为" +
                (i + 1) +
                "的房间必须房间号支持输入数字、汉字，大小写字母，限制字符长度1~24个字符",
              type: "warning",
            });
          }
        }

        let obj = {
          ...baseData,
          ...room,
        };
        dto.push(obj);
      }
      let dtoDatas = [];
      for (let i = 0; i < dto.length; i++) {
        _this.apartmentList.forEach((item) => {
          if (dto[i].apartmentId == item.value) {
            dto[i].apartmentName = item.label;
          }
        });
        _this.buildingsList.forEach((item) => {
          if (dto[i].buildingNo == item.value) {
            dto[i].buildName = item.label;
          }
        });
        dto[i].roomInfo =
          dto[i].apartmentName + dto[i].buildName + dto[i].roomNo;
        // 是否发布到房源页，默认不发布
        dto[i].isShow = 1;
        if (dto[i].roomNo != "") {
          dtoDatas.push(dto[i]);
        }
      }

      _this
        .post("/landlord-service/room/createRoomBatch", dtoDatas)
        .then(function () {
          _this.$message({
            showClose: true,
            message: "批量创建房间成功",
            type: "success",
          });
          _this.$back();
        });
    },
    longFun(e) {
      console.log(e);
      if (e > 100) {
        this.$message({
          showClose: true,
          message: "最多添加至100项",
          type: "warning",
        });
      } else {
        if (e < 0) {
          e = 1;
          this.roomNumLong = 1;
        }
        let _listLong = [];
        for (let i = 0; i < e; i++) {
          _listLong.push(JSON.parse(JSON.stringify(this.roomTemplate)));
        }
        this.roomList = _listLong;
      }
    },
  },
};
</script>
<style lang="scss">
.room-batch {
  .el-form-item__label {
    flex: none !important;
    width: 150px;
  }
  .row-title {
    margin: 0 auto;
    padding-bottom: 6px;
    margin-top: 10px;
    margin-bottom: 15px;
    max-width: 1200px;
    color: #606266;
    font-size: 16px;
    border-bottom: solid 1px #e4e7ed;
  }

  .row-line {
    margin: 0 auto;
    margin-bottom: 15px;
    max-width: 1200px;
    border-bottom: solid 1px #e4e7ed;
  }

  .list-box {
    margin: 0 auto;
    max-width: 1200px;
  }

  .list-item {
    padding: 4px 0;
    margin-bottom: 6px;
    background-color: #eee;
    .list-idx {
      display: inline-block;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-size: 14px;
      color: #606266;
    }
    .list-col {
      display: inline-block;
      label {
        margin-left: 10px;
        margin-right: 6px;
        font-size: 14px;
        color: #606266;
        line-height: 40px;
      }
      .el-input {
        width: 120px;
      }
    }
    .list-cols {
      display: inline-block;
      label {
        margin-left: 10px;
        margin-right: 6px;
        font-size: 14px;
        color: #606266;
        line-height: 40px;
      }
      .el-input {
        width: 180px;
      }
      span {
        font-size: 14px;
        color: red;
        margin-left: 20px;
      }
    }
    .list-buts {
      float: right;
      padding-right: 10px;
    }
  }

  .action-but {
    padding-left: 0;
    margin-top: 50px;
    margin-bottom: 30px;
  }
}
.app-form .el-form .item-box-33 .el-form-item__label {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 150px;
}
</style>
